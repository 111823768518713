$ln-webpack: true;

$ln-theme: 'sainsburys';

@import '@jsluna/style/scss/main.scss';

// Colors
.color-delete-warning{
  color: #FF00B8;
}


.is-open {
    .ln-c-header__main-bar::before {
      left: 0 !important;
    }
  }


  .show-notification{
    .notifications-panel{
      right: 0 !important;
    }
  }
  
  .show-side-panel{
    overflow: hidden !important;
    .global-side-panel{
      left: 0 !important;

      &::before{
        width: calc(100vw + 80px) !important;
      }
    }
  }


.MuiMenuItem-root {
    &:focus  {
      box-shadow: none !important;
      background: transparent !important;
    }
  }


.menu-item {
  font-family: var(--ds-font-stack-display-2-font-family)!important;
  font-weight: bold !important;
  font-size: 18px !important;
}

.label-1{
  font-family: var(--ds-font-stack-display-2-font-family)!important;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 24px;
}

.display-1{
  font-family: var(--ds-font-stack-display-2-font-family)!important;
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 26px;
}
.display-2{
    color: var(--monochrome-darker, #262626);
    font-family: var(--ds-font-stack-display-2-font-family)!important;
    font-size: 26px;
    font-style: normal;
    font-weight: 800;
    line-height: 32px; 
}
.display-3{
  font-family: var(--ds-font-stack-display-2-font-family)!important;
  font-style: normal;
  font-weight: 800;
  font-size: 26px;
  line-height: 32px;
}


  h3{
    font-size: var(--ds-font-stack-display-3-font-size);
    line-height: var(--ds-font-stack-display-3-line-height);
    font-weight: var(--ds-font-stack-display-3-font-weight);
    font-family: var(--ds-font-stack-display-3-font-family);
  }

  h4{
    font-size: var(--ds-font-stack-display-2-font-size);
    line-height: var(--ds-font-stack-display-2-line-height);
    font-weight: var(--ds-font-stack-display-2-font-weight);
    font-family: var(--ds-font-stack-display-2-font-family);
  }

  .body-1{
    font-size: var(--ds-font-stack-body-1-font-size);
    line-height: var(--ds-font-stack-body-1-line-height);
    font-weight: var(--ds-font-stack-body-1-font-weight);
    font-family: var(--ds-font-stack-body-1-font-family);
  }
  .body-2{
    font-size: var(--ds-font-stack-body-2-font-size);
    line-height: var(--ds-font-stack-body-2-line-height);
    font-weight: var(--ds-font-stack-body-2-font-weight);
    font-family: var(--ds-font-stack-body-2-font-family);
  }

  .ln-c-button--text.ln-c-button--dark:not(:disabled):not(.is-disabled):hover,
  .ln-c-button--text.ln-c-button--dark:not(:disabled):not(.is-disabled):focus 
  {
    background-color: transparent;
    color: var(--ds-color-palette-brand-1-highlight);
  }

  .drawer-on-top{
    z-index: 2000 !important;
  }

  .app-tile-flag_top_app-name{
    width: 100% !important;
  }


  // Icon Utils

  .green-circle {
    width: 25px;
    height: 25px;
    background-color: #2E7D32;
    border-radius: 50%;
    color: white;
    padding: 2.5px;
    margin-right: 12px;
  }

  .yellow-circle {
    width: 25px;
    height: 25px;
    background-color: rgb(255, 214, 0);
    border-radius: 50%;
    color: white;
    padding: 2.5px;
    margin-right: 12px;
  }

  // UI Customisations

  .working-with-sainsburys-list-item{
    border-radius: 8px;
  }

.ln-c-button--icon-text {
  padding-right: 12px;
}

.ln-c-accordion:not([class~="ln-c-accordion@"]) > .ln-c-accordion__head {
    padding-bottom: 0;
    padding-top: 0;
}

.account-type-accordion.ln-c-accordion.is-open .ln-c-accordion__head .ln-c-accordion__title {
  padding-bottom: var(--ds-space-spacing-x1);
  padding-top: var(--ds-space-spacing-x1);
}


.permission-group-accordion.is-open{

  .accordion-title{
    color: #F06C00 !important;
  }

  .ln-c-accordion__icon.ln-c-icon{
    color: #F06C00 !important;
  }
}

.p-relative{
  position: relative;
}

.feedback-zone {
  position: relative;

  .notification-wrapper {
    top: -48px;
  }
}


.no-mb{
  margin-bottom: 0 !important;
}

.add-tags-modal.ln-c-modal.is-open{
  z-index: 1600;
}


.news-and-articles__article-page {
  .dm-box--video__wrapper,
  iframe {
    width: 100% !important; // Force iframes to 100%
    min-height: 22rem;  // Height bug in video player sometimes being squashed vertically
    padding-bottom: 0 !important; // Sometimes CS adds a bunch of padding
  }
}


